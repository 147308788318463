export const attendanceData = [
    {
      "id": 1,
      "date": "2024-05-01",
      "punchInTime": "09:00",
      "punchOutTime": "17:00",
      "break": "1 hrs",
      "overtime": "0"
    },
    {
      "id": 2,
      "date": "2024-05-02",
      "punchInTime": "08:30",
      "punchOutTime": "16:30",
      "break": "45 min",
      "overtime": "0"
    },
    {
      "id": 3,
      "date": "2024-05-03",
      "punchInTime": "09:15",
      "punchOutTime": "17:15",
      "break": "1 hrs",
      "overtime": "0"
    },
    {
      "id": 4,
      "date": "2024-05-04",
      "punchInTime": "08:45",
      "punchOutTime": "16:45",
      "break": "45 hrs",
      "overtime": "0"
    },
    {
      "id": 5,
      "date": "2024-05-05",
      "punchInTime": "09:30",
      "punchOutTime": "17:30",
      "break": "1 hrs",
      "overtime": "0"
    },
    {
      "id": 6,
      "date": "2024-05-06",
      "punchInTime": "09:00",
      "punchOutTime": "18:00",
      "break": "1 hrs",
      "overtime": "1 hrs"
    },
    {
      "id": 7,
      "date": "2024-05-07",
      "punchInTime": "08:45",
      "punchOutTime": "16:45",
      "break": "45 min",
      "overtime": "0"
    },
    {
      "id": 8,
      "date": "2024-05-08",
      "punchInTime": "09:15",
      "punchOutTime": "17:15",
      "break": "1 hrs",
      "overtime": "0"
    }
  ]
  