import avatar from '../../../assets/images/users/avatar-6.jpg'

export const leaveData = [
    {
        'id': 1,
        'name': 'JOHN DEO',
        'img': avatar,
        'serviceTime': '5 Years Service',
        'day': 5,

    }
]