export const holidayData = [
    {
      "id": 1,
      "title": "New Year's Day",
      "date": "2024-01-01",
      "day": "Tuesday"
    },
    {
      "id": 2,
      "title": "Independence Day",
      "date": "2024-07-04",
      "day": "Thursday"
    },
    {
      "id": 3,
      "title": "Christmas Day",
      "date": "2024-12-25",
      "day": "Wednesday"
    },
    {
      "id": 4,
      "title": "Thanksgiving Day",
      "date": "2024-11-28",
      "day": "Thursday"
    },
    {
      "id": 5,
      "title": "Labor Day",
      "date": "2024-09-02",
      "day": "Monday"
    },
    {
      "id": 6,
      "title": "Memorial Day",
      "date": "2024-05-27",
      "day": "Monday"
    },
    {
      "id": 7,
      "title": "Veterans Day",
      "date": "2024-11-11",
      "day": "Monday"
    },
    {
      "id": 8,
      "title": "Easter Sunday",
      "date": "2024-04-21",
      "day": "Sunday"
    }
  ]
  