import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../../assets/images/users/avatar-4.jpg';
import avatar5 from '../../../assets/images/users/avatar-5.jpg';
import avatar6 from '../../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../../assets/images/users/avatar-8.jpg';
import avatar9 from '../../../assets/images/users/avatar-9.jpg';
import avatar10 from '../../../assets/images/users/avatar-10.jpg';

export const leavesData = [
    {
      "id": 1,
      "name": "John Doe",
      "img": avatar1,
      "leaveType": "Annual Leave",
      "from": "2024-06-01",
      "to": "2024-06-03",
      "noOfDay": 3,
      'remaining': 4,
      "reason": "Vacation with family",
      "status": "Approved"
    },
    {
      "id": 2,
      "name": "Alice Smith",
      "img": avatar2,
      "leaveType": "Sick Leave",
      "from": "2024-07-10",
      "to": "2024-07-11",
      "noOfDay": 2,
      'remaining': 4, 
      "reason": "Flu",
      "status": "Approved"
    },
    {
      "id": 3,
      "name": "Emily Johnson",
      "img": avatar3,
      "leaveType": "Maternity Leave",
      "from": "2024-09-15",
      "to": "2024-12-15",
      "noOfDay": 92,
      'remaining': 4,
      "reason": "Maternity",
      "status": "Pending"
    },
    {
      "id": 4,
      "name": "Michael Brown",
      "img": avatar4,
      "leaveType": "Emergency Leave",
      "from": "2024-10-20",
      "to": "2024-10-20",
      "noOfDay": 1,
      'remaining': 4,
      "reason": "Family emergency",
      "status": "Approved"
    },
    {
      "id": 5,
      "name": "Emma Wilson",
      "img": avatar5,
      "leaveType": "Personal Leave",
      "from": "2024-11-05",
      "to": "2024-11-06",
      "noOfDay": 2,
      'remaining': 4,
      "reason": "Attending friend's wedding",
      "status": "Approved"
    },
    {
      "id": 6,
      "name": "William Miller",
      "img": avatar6,
      "leaveType": "Annual Leave",
      "from": "2024-12-20",
      "to": "2024-12-30",
      "noOfDay": 11,
      'remaining': 4,
      "reason": "Vacation abroad",
      "status": "Pending"
    },
    {
      "id": 7,
      "name": "Sophia Wilson",
      "img": avatar7,
      "leaveType": "Sick Leave",
      "from": "2024-03-05",
      "to": "2024-03-07",
      "noOfDay": 3,
      'remaining': 4,
      "reason": "Cold and fever",
      "status": "Approved"
    },
    {
      "id": 8,
      "name": "James Taylor",
      "img": avatar8,
      "leaveType": "Emergency Leave",
      "from": "2024-08-10",
      "to": "2024-08-12",
      "noOfDay": 3,
      'remaining': 4,
      "reason": "Medical emergency",
      "status": "Rejected"
    },
    {
      "id": 9,
      "name": "Olivia Anderson",
      "img": avatar9,
      "leaveType": "Personal Leave",
      "from": "2024-04-25",
      "to": "2024-04-26",
      "noOfDay": 2,
      'remaining': 4,
      "reason": "Attending graduation ceremony",
      "status": "Approved"
    },
    {
      "id": 10,
      "name": "David Martinez",
      "img": avatar10,
      "leaveType": "Maternity Leave",
      "from": "2024-11-20",
      "to": "2025-02-20",
      "noOfDay": 93,
      'remaining': 4,
      "reason": "Maternity",
      "status": "Pending"
    }
  ]
  