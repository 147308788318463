import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../../assets/images/users/avatar-4.jpg';
import avatar5 from '../../../assets/images/users/avatar-5.jpg';
import avatar6 from '../../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../../assets/images/users/avatar-7.jpg';
import avatar8 from '../../../assets/images/users/avatar-8.jpg';
import avatar9 from '../../../assets/images/users/avatar-9.jpg';
import avatar10 from '../../../assets/images/users/avatar-10.jpg';
import avatar11 from '../../../assets/images/users/avatar-11.jpg';
import avatar12 from '../../../assets/images/users/avatar-12.jpg';

export const employeesData = [
    {
      "id": 1,
      "name": "John Smith",
      "userName": "John123",
      "employeeId": "EMP001",
      "email": "john.smith@example.com",
      "mobile": "+1234567890",
      "joiningDate": "2022-01-15",
      "role": "Software Engineer",
      "img": avatar1
    },
    {
      "id": 2,
      "name": "Emily Johnson",
      "userName": "Emily123",
      "employeeId": "EMP002",
      "email": "emily.johnson@example.com",
      "mobile": "+1987654321",
      "joiningDate": "2021-11-20",
      "role": "UX Designer",
      "img": avatar2
    },
    {
      "id": 3,
      "name": "Michael Brown",
      "userName": "Michael123",
      "employeeId": "EMP003",
      "email": "michael.brown@example.com",
      "mobile": "+1555098765",
      "joiningDate": "2022-02-28",
      "role": "Project Manager",
      "img": avatar3
    },
    {
      "id": 4,
      "name": "Sarah Williams",
      "userName": "Sarah123",
      "employeeId": "EMP004",
      "email": "sarah.williams@example.com",
      "mobile": "+1444567890",
      "joiningDate": "2022-03-10",
      "role": "Marketing Specialist",
      "img": avatar4
    },
    {
      "id": 5,
      "name": "David Jones",
      "userName": "David123",
      "employeeId": "EMP005",
      "email": "david.jones@example.com",
      "mobile": "+1333456789",
      "joiningDate": "2021-12-05",
      "role": "Frontend Developer",
      "img": avatar5
    },
    {
      "id": 6,
      "name": "Emma Garcia",
      "userName": "Emma123",
      "employeeId": "EMP006",
      "email": "emma.garcia@example.com",
      "mobile": "+1777123456",
      "joiningDate": "2022-04-03",
      "role": "HR Manager",
      "img": avatar6
    },
    {
      "id": 7,
      "name": "James Martinez",
      "userName": "James123",
      "employeeId": "EMP007",
      "email": "james.martinez@example.com",
      "mobile": "+1666789451",
      "joiningDate": "2021-09-18",
      "role": "Backend Developer",
      "img": avatar7
    },
    {
      "id": 8,
      "name": "Olivia Brown",
      "userName": "Olivia123",
      "employeeId": "EMP008",
      "email": "olivia.brown@example.com",
      "mobile": "+1888321654",
      "joiningDate": "2022-05-22",
      "role": "Graphic Designer",
      "img": avatar8
    },
    {
      "id": 9,
      "name": "Daniel Wilson",
      "userName": "Daniel123",
      "employeeId": "EMP009",
      "email": "daniel.wilson@example.com",
      "mobile": "+1666789451",
      "joiningDate": "2021-10-12",
      "role": "Sales Manager",
      "img": avatar9
    },
    {
      "id": 10,
      "name": "Sophia Rodriguez",
      "userName": "Sophia123",
      "employeeId": "EMP010",
      "email": "sophia.rodriguez@example.com",
      "mobile": "+1444456789",
      "joiningDate": "2022-06-08",
      "role": "Data Analyst",
      "img": avatar10
    },
    {
      "id": 11,
      "name": "Alexander Taylor",
      "userName": "Taylor123",
      "employeeId": "EMP011",
      "email": "alexander.taylor@example.com",
      "mobile": "+1999445566",
      "joiningDate": "2022-03-25",
      "role": "Content Writer",
      "img": avatar11
    },
    {
      "id": 12,
      "name": "Ava Hernandez",
      "userName": "Ava123",
      "employeeId": "EMP012",
      "email": "ava.hernandez@example.com",
      "mobile": "+1222345678",
      "joiningDate": "2022-07-15",
      "role": "Customer Support Representative",
      "img": avatar12
    }
  ]  