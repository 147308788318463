import avatar1 from '../../../assets/images/users/avatar-1.jpg';
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../../assets/images/users/avatar-4.jpg';
import avatar5 from '../../../assets/images/users/avatar-5.jpg';
import avatar6 from '../../../assets/images/users/avatar-6.jpg';
import avatar7 from '../../../assets/images/users/avatar-7.jpg';

export const attendanceData = [
    {
        "id": 1,
        "employeeName": "John Doe",
        "img": avatar1,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 2,
        "employeeName": "Jane Smith",
        "img": avatar2,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 3,
        "employeeName": "Alice Johnson",
        "img": avatar3,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 4,
        "employeeName": "Michael Brown",
        "img": avatar4,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 5,
        "employeeName": "Emily Davis",
        "img": avatar5,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 6,
        "employeeName": "David Wilson",
        "img": avatar6,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
    {
        "id": 7,
        "employeeName": "Jessica Martinez",
        "img": avatar7,
        "attendance": [
            { "day": 1, "status": "present", "date": "2024-04-01", "punchIn": "08:00", "punchOut": "17:00", "break": "1.21", "overtime": "0" },
            { "day": 2, "status": "absent" },
            { "day": 3, "status": "present", "date": "2024-04-03", "punchIn": "08:15", "punchOut": "16:45", "break": "1.30", "overtime": "0" },
            { "day": 4, "status": "present", "date": "2024-04-04", "punchIn": "08:05", "punchOut": "17:10", "break": "1.25", "overtime": "0" },
            { "day": 5, "status": "absent" },
            { "day": 6, "status": "present", "date": "2024-04-06", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "1" },
            { "day": 7, "status": "present", "date": "2024-04-07", "punchIn": "08:10", "punchOut": "17:20", "break": "1", "overtime": "0" },
            { "day": 8, "status": "present", "date": "2024-04-08", "punchIn": "08:00", "punchOut": "17:00", "break": "1.20", "overtime": "0" },
            { "day": 9, "status": "absent" },
            { "day": 10, "status": "present", "date": "2024-04-10", "punchIn": "08:30", "punchOut": "17:45", "break": "1.25", "overtime": "2" },
            { "day": 11, "status": "absent" },
            { "day": 12, "status": "present", "date": "2024-04-12", "punchIn": "08:10", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 13, "status": "present", "date": "2024-04-13", "punchIn": "08:15", "punchOut": "17:25", "break": "1.10", "overtime": "0" },
            { "day": 14, "status": "absent" },
            { "day": 15, "status": "present", "date": "2024-04-15", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "0" },
            { "day": 16, "status": "present", "date": "2024-04-16", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 17, "status": "present", "date": "2024-04-17", "punchIn": "08:10", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 18, "status": "absent" },
            { "day": 19, "status": "present", "date": "2024-04-19", "punchIn": "08:15", "punchOut": "17:20", "break": "1.20", "overtime": "0" },
            { "day": 20, "status": "present", "date": "2024-04-20", "punchIn": "08:20", "punchOut": "17:30", "break": "1.25", "overtime": "1" },
            { "day": 21, "status": "absent" },
            { "day": 22, "status": "present", "date": "2024-04-22", "punchIn": "08:05", "punchOut": "17:15", "break": "1.45", "overtime": "0" },
            { "day": 23, "status": "absent" },
            { "day": 24, "status": "absent" },
            { "day": 25, "status": "present", "date": "2024-04-25", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" },
            { "day": 26, "status": "present", "date": "2024-04-26", "punchIn": "08:20", "punchOut": "17:30", "break": "1.45", "overtime": "0" },
            { "day": 27, "status": "present", "date": "2024-04-27", "punchIn": "08:00", "punchOut": "17:10", "break": "1.20", "overtime": "0" },
            { "day": 28, "status": "present", "date": "2024-04-28", "punchIn": "08:10", "punchOut": "17:20", "break": "1.25", "overtime": "0" },
            { "day": 29, "status": "absent" },
            { "day": 30, "status": "present", "date": "2024-04-30", "punchIn": "08:15", "punchOut": "17:25", "break": "1.30", "overtime": "0" }
        ]
    },
]  